import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { format } from 'date-fns'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import SEO from '../components/seo'

export default function Template({ location, data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, timeToRead, excerpt } = markdownRemark
  const featuredImage = getImage(frontmatter.featuredImage)
  return (
    <Layout>
      <SEO
        title={`${frontmatter.title} by Emiliano Viscarra | Techeria`}
        pathname={location.pathname}
        desc={excerpt}
        banner={frontmatter.featuredImage.publicURL}
        article
        date={format(
          new Date(frontmatter.date),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        )}
      />
      <div className="relative py-2 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="prose prose-sm sm:prose mx-auto">
            <GatsbyImage
              image={featuredImage}
              alt=""
              className="h-48 w-full object-cover"
            />
            <h1>
              <span className="font-serif mt-6 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                {frontmatter.title}
              </span>
            </h1>
          </div>
          <div className="max-w-prose mx-auto mt-6 flex items-center">
            <div className="flex-shrink-0">
              <Link to="/blog">
                <span className="sr-only">Emiliano Viscarra</span>
                <StaticImage
                  src="../images/avatar-emi.jpg"
                  placeholder="blurred"
                  alt="Author Emiliano"
                  width={40}
                  height={40}
                  className="h-10 w-10 rounded-full"
                />
              </Link>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                <Link to="/blog" className="hover:underline">
                  Emiliano Viscarra
                </Link>
              </p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime={frontmatter.date}>
                  {format(new Date(frontmatter.date), 'PP')}
                </time>
                <span aria-hidden="true">&middot;</span>
                <span>{timeToRead} mins read</span>
              </div>
            </div>
          </div>
          <article
            className="mt-6 prose prose-teal text-gray-600 mx-auto"
            dangerouslySetInnerHTML={{ __html: html }}
          ></article>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 800)
          }
          publicURL
        }
      }
      timeToRead
      excerpt
    }
  }
`
